#about {
  .team {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    width: 100%;

    .member {
      display: flex;
      flex-direction: column;
      gap: 10px;

      i {
        transition: filter 0.3s ease;

        &:hover {
          filter: brightness(1.2);
        }
      }

      img {
        border-radius: 50%;
        width: 180px;
      }

      nav {
        font-size: 30px;
      }

      .about p {
        color: black;
      }

      .linkedin {
        color: #0077b5;
      }

      .twitter {
        color: #1da1f2;
      }
    }
  }
}
