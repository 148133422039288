#contact {
  background-color: #e3e3e3;

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: Min(700px, 100%);

    button, input, textarea {
      border: none;
      font: inherit;
      padding: 1rem;

      &:focus {
        outline: none;
      }
    }

    button {
      background-color: $blue;
      border: 1px solid $blue;
      border-radius: 50px;
      color: white;
      transition: all 0.5s ease;

      &:hover {
        background-color: white;
        color: $blue;
      }
    }

    .error {
      color: rgb(192, 50, 50);
    }
  }
}
