#solution {
  background-color: #e3e3e3;

  h2 {
    margin-bottom: -20px;
  }

  .solutions {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    width: 100%;

    .solution {
      background-color: white;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      overflow: hidden;
      transition: opacity 0.5s ease;
      width: 315px;

      &:hover {
        opacity: 0.7;
      }

      .content {
        color: #00000080;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 10px;
        justify-content: space-between;
        padding: 10px;

        h4 {
          color: black;
          font-size: 25px;
        }

        p {
          flex-grow: 1;
        }
      }
    }

    @media screen and (min-width: 560px) {
      align-items: normal;
      flex-direction: row;

      .solution {
        max-width: 33%;
      }
    }
  }
}
