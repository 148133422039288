.banner {
  background-image: url("../images/background.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  height: 100vh;
  height: 100svh;

  &:hover .chevron {
    opacity: 1;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 20px 0;
  }

  button {
    background-color: transparent;
    border: none;
  }

  .chevron {
    align-self: center;
    border: 2px solid #00000080;
    border-radius: 50%;
    font-size: 25px;
    height: 45px;
    margin-bottom: 20px;
    opacity: 0;
    transition: opacity 0.5s ease;
    width: 45px;
  }

  .navbar {
    align-items: center;
    display: flex;
    justify-content: space-between;

    img {
      width: 150px;
    }

    .menu {
      position: relative;

      i {
        font-size: 30px;
      }

      nav {
        align-items: flex-end;
        background-color: white;
        border-radius: 5px;
        color: $blue;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: max-content;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0 20px;
        position: absolute;
        right: 0;
        top: calc(100% + 5px);
        transition: all 0.5s ease;

        &.show {
          max-height: 200px;
          opacity: 1;
          padding: 10px 20px;
        }

        button {
          font-family: $header-font;
          text-transform: uppercase;
          transition: all 0.5s ease;

          &:hover {
            color: $blue;
            translate: 0 -30%;
          }
        }
      }
    }
  }

  .title {
    h1 {
      font-size: 45px;
      line-height: 60px;
      margin-bottom: 100px;
    }

    h3 {
      color: #2fc6ff;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 560px) {
    .navbar .menu {
      i {
        display: none;
      }

      nav {
        align-items: normal;
        background-color: transparent;
        color: white;
        flex-direction: row;
        gap: 40px;
        max-height: 200px;
        opacity: 1;
        padding: 0;
        position: relative;
      }
    }
    .title h1 {
      font-size: 60px;
    }
  }
}
