* {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

button {
  color: inherit;
  cursor: pointer;
  font: inherit;
}

body {
  font-family: $body-font;
  height: 1px;
  min-height: 100vh;
  text-align: center;
}

h1, h2, h3 {
  font-family: $header-font;
}

h2 {
  color: $purple;
  font-size: 30px;
}

h3 {
  color: $blue;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

img {
  max-width: 100%;
}

nav, ul {
  list-style: none;
}

p {
  color: #6c6d6d;
}

.container {
  margin: 0 auto;
  width: Min(1200px, 90%);
}

.paragraphs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 50px 0;
}
