#landing {
  .arguments {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    width: 100%;

    img {
      width: 130px;
    }

    @media screen and (min-width: 560px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
