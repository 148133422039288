#terms {
  background-color: #e3e3e3;

  p {
    color: black;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: start;
    width: 100%;

    > div {
      display: flex;
      gap: 10px;

      &.content {
        flex-direction: column;
        gap: 0;
      }
    }

    a {
      background-color: $blue;
      border: 1px solid $blue;
      border-radius: 50px;
      color: white;
      font: inherit;
      padding: 1rem;
      transition: all 0.5s ease;

      &:hover {
        background-color: white;
        color: $blue;
      }
    }
  }

  .logo {
    background-color: white;
    display: block;

    img {
      filter: brightness(0);
      width: 150px;
    }
  }
}
