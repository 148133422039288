#solution-page {
  a {
    background-color: $blue;
    border: 1px solid $blue;
    border-radius: 50px;
    color: white;
    font: inherit;
    padding: 1rem;
    transition: all 0.5s ease;

    &:hover {
      background-color: white;
      color: $blue;
    }
  }

  img {
    width: 500px;
  }

  em {
    color: #6c6d6d;
  }

  .paragraphs {
    text-align: start;
  }
}
