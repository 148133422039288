@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600&family=Roboto:wght@300;400;500&display=swap');

// Colours
$blue: #2fc6ff;
$purple: #4c4183;

// Fonts
$body-font: 'Roboto', sans-serif;
$header-font: 'Palanquin Dark', sans-serif;

// Font-awesome
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
