footer {
  background-color: #373e46;
  color: white;

  .icons {
    display: grid;
    font-size: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    button {
      align-items: center;
      border: none;
      border-radius: 50%;
      color: #373e46;
      display: none;
      height: 70px;
      justify-content: center;
      margin: 0 auto;
      transition: opacity 0.3s ease;
      width: 70px;

      &:hover {
        opacity: 0.7;
      }
    }

    nav {
      display: flex;
      gap: 20px;
      margin: 0 auto;
    }
  }

  .top a {
    border-right: 2px solid white;
    margin-right: 20px;
    padding-right: 20px;

    &:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (min-width: 560px) {
    .icons button {
      display: flex;
    }
  }
}
